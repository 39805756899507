import axios from "axios";
import { startSessionTimer } from "../../helpers/SessionTimer";

export function logIn(response) {
  const {
    accessToken,
    tokenExpirationDate,
    username,
    isAdUser,
    isTempPassword
  } = response.data;
  if (accessToken && tokenExpirationDate && username) {
    sessionStorage.setItem("accessToken", accessToken);
    sessionStorage.setItem("expires", tokenExpirationDate);
    sessionStorage.setItem("userName", username);
    sessionStorage.setItem("adUser", isAdUser);
    sessionStorage.setItem("tempPass", isTempPassword);
  } else {
    throw new Error(`Error Code ${response.status}`);
  }
}

export function logOut() {
  sessionStorage.clear();
  window.location.assign("/");
}

export function userIsAuthenticated() {
  if (
    sessionStorage.getItem("accessToken") &&
    sessionStorage.getItem("expires")
  ) {
    startSessionTimer(logOut, 640);
    return true;
  }
  return false;
}

export function passwordIsTemporary() {
  if (sessionStorage.getItem("tempPass") === "true") {
    return true;
  }
  return false;
}

export function createResponseInterceptor() {
  const interceptor = axios.interceptors.response.use(
    // If the request succeeds, return the original response
    response => {
      return response;
    },
    // If there is an error, use the Interceptor to handle the error.
    error => {
      const status = error.response ? error.response.status : null;
      if (status !== 401) {
        return Promise.reject(error);
      } else {
        axios.interceptors.response.eject(interceptor);
        if (window.location.pathname === "/") {
          return Promise.reject(error);
        } else {
          logOut();
        }
      }
    }
  );
}
