import React from "react";
// Material-UI imports
import { CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
// Custom imports
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `/pdf.worker.js`;

export default function CheckView(props) {
  const classes = useStyles();

  let document;
  if (props.checkSrc) {
    document = (
      <Document
        file={`data:application/pdf;base64,${props.checkSrc}`}
        loading={
          <CircularProgress size={24} className={classes.buttonProgress} />
        }
      >
        <Page pageNumber={1} className={classes.iframe} />
      </Document>
    );
  } else {
    document = (
      <CircularProgress size={24} className={classes.buttonProgress} />
    );
  }

  return document;
}

const useStyles = makeStyles(theme => ({
  iframe: {
    width: "100%",
    height: "calc(100% - 8px)",
    transform: "scale(1.25)",
    transformOrigin: "top left"
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  }
}));
