export default function getApiUrl() {
  const hostname = window.location.hostname;
  try {
    // Use the internal API for local development
    if (hostname.indexOf("localhost") !== -1) {
      return "gateway.ghllc.com";
    }
    // Use the internal API internally
    else if (hostname.indexOf("portal.corp.ghtulsa.com") !== -1) {
        return "gateway.ghllc.com";
    }
    //  Use the external API externally
    else if (hostname.indexOf("app.ghtulsa.com") !== -1) {
        return "gateway.ghllc.com";
    } else {
      throw new Error(
        "API could not be found. Please connect to this site via a URL instead of an IP address."
      );
    }
  } catch (error) {
    console.log(error);
  }
}
