export default function getPhonepayApi() {
  const hostname = window.location.hostname;
  try {
    // Use the Dev API for local development
    if (hostname.indexOf("localhost") !== -1) {
      return "https://dev.api";
    }
    // Dev API in dev environment
    else if (hostname.indexOf("dev") !== -1) {
      return "https://dev.api";
    }
    // QA API in QA environment
    else if (hostname.indexOf("qa") !== -1) {
      return "https://qa.api";
    }
    // Internal Prod
    else if (hostname.indexOf("portal") !== -1) {
      return "https://api";
    }
    // External Prod
    else if (hostname.indexOf("app") !== -1) {
      return "https://api";
    } else {
      throw new Error(
        "API could not be found. Please connect to this site via a URL instead of an IP address."
      );
    }
  } catch (error) {
    console.log(error);
  }
}
