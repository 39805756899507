import axios from "axios";

export default function fetchApi(
  method,
  url,
  data = {},
  accessToken = sessionStorage.accessToken
) {
  return new Promise((resolve, reject) => {
    const fetchConfig = {
      method: method,
      url: url,
      data: data,
      headers: {
        "Content-Type": "application/json",
        "Cache-Control": "no-cache",
        Authorization: `Bearer ${accessToken}`
      }
    };
    axios(fetchConfig)
      .then(response => {
        if (response.status === 200) {
          resolve(response);
        } else {
          throw new Error(`Error Code ${response.status}`);
        }
      })
      .catch(error => {
        reject(error);
      });
  });
}
