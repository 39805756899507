import React from "react";
// Material-UI imports
import { Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
// Custom imports
import Navigation from "../../components/Navigation";
import GetCheck from "../../components/GetCheck";

export default function Layout() {
  const classes = useStyles();

  return (
    <div className={classes.background}>
      <div className={classes.navigationContainer}>
        <Navigation userName={sessionStorage.userName.split("@")[0]} />
      </div>
      <Paper elevation={2} className={classes.checkContainer}>
        <GetCheck userName={sessionStorage.userName.split("@")[0]} />
      </Paper>
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  background: {
    background: "#f2f2f2"
  },
  navigationContainer: {
    flex: "0 0 100%",
    height: "80px"
  },
  checkContainer: {
    margin: 16,
    height: "calc(100vh - 80px - 32px)",
    display: "flex"
  }
}));
