import React, { Fragment, useState, useEffect, useCallback } from "react";
// Material-UI imports
import { Typography, Modal, Button, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
// Custom imports
import Form from "../CheckDetailsForm";
import CheckView from "../CheckPDFView";
import { startSessionTimer } from "../../helpers/SessionTimer";
import getApiUrl from "../../helpers/GetApiUrl";
import getPhonepayApi from "../../helpers/GetPhonePayApi";
import fetchApi from "../../services/FetchApi";
import Timer from "../../components/Timer";
import { logOut } from "../../services/Authentication";

export default function GetCheck(props) {
  const classes = useStyles();
  const [checkSrc, setCheckSrc] = useState("");
  const [checkData, setCheckData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState([]);
  const [overlay, setOverlay] = useState(false);

  const handleOpen = () => {
    setModalOpen(true);
  };
  const handleClose = () => {
    setModalOpen(false);
    window.location.reload();
  };
  const handleErrorOpen = () => {
    setError(true);
  };
  const handleErrorClose = () => {
    setError(false);
    window.location.reload();
  };

  const getCheckData = useCallback(() => {
    const checkviewerEndpoint = `${getPhonepayApi()}.${getApiUrl()}/phonepay/checkviewer`;
    fetchApi("GET", checkviewerEndpoint)
      .then((response) => {
        if (response.status === 200) {
          setCheckSrc(response.data.checkImage);
          setCheckData(response.data);
          startSessionTimer(handleOpen, 30);
          setError(false);
          setOverlay(false);
          if (response.data.status === 500) {
            setErrorMessage(
              <Timer message={`${response.data.detail}.`} seconds={5} />
            );
            handleErrorOpen();
          } else {
            setCheckSrc(response.data.checkImage);
            setCheckData(response.data);
            startSessionTimer(handleOpen, 30);
            setError(false);
            setOverlay(false);
          }
        } else {
          setErrorMessage(<Timer seconds={5} />);
          handleErrorOpen();
          setTimeout(() => {
            getCheckData();
          }, 5000);
        }
      })
      .catch((error) => {
        setErrorMessage(<Timer seconds={5} />);
        handleErrorOpen();
        setTimeout(() => {
          getCheckData();
        }, 5000);
      });
  }, []);

  const overlayCheckImage = () => {
    setOverlay(true);
  };

  useEffect(() => {
    getCheckData();
  }, [getCheckData]);

  return (
    <Fragment>
      <div className={classes.formContainer}>
        <Form
          checkData={checkData}
          userName={props.userName}
          onChange={getCheckData}
          inProgress={overlayCheckImage}
        />
      </div>
      <div className={classes.checkViewContainer}>
        {overlay && <div className={classes.overlay}></div>}
        {checkData.remainingCheckCount === 0 &&
        checkData.checkImage === null ? (
          <div className={classes.noMoreChecks}>
            No more checks in the queue.
          </div>
        ) : (
          <CheckView checkSrc={checkSrc} />
        )}
      </div>
      <Modal
        aria-labelledby="sessiontimer-modal"
        open={modalOpen}
        onClose={handleClose}
      >
        <div className={classes.modalContainer}>
          <Typography variant="body1" component="p">
            Are you still there?
          </Typography>
          <Box py={1}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              onClick={handleClose}
            >
              Yes
            </Button>
          </Box>
        </div>
      </Modal>
      <Modal
        aria-labelledby="error-modal"
        open={error}
        onClose={handleErrorClose}
      >
        <div className={classes.modalContainer}>
          <Typography variant="body1" component="p">
            {errorMessage}
          </Typography>
          <Box py={2}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              onClick={logOut}
            >
              Log out
            </Button>
          </Box>
        </div>
      </Modal>
    </Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  formContainer: {
    flex: "0 0 320px",
  },
  checkViewContainer: {
    flex: "1 1 calc(100% - 320px)",
    background: "#f2f3f8",
    overflowY: "auto",
    overflowX: "auto",
  },
  modalContainer: {
    position: "absolute",
    width: 400,
    backgroundColor: "#fff",
    border: "none",
    boxShadow: "0 0 5px #888",
    padding: 32,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  overlay: {
    position: "absolute",
    background: "rgba(255,255,255,.75)",
    width: "100%",
    height: "100%",
    zIndex: "1000",
  },
  noMoreChecks: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
  },
}));
