import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
import React from "react";
import { render } from "react-dom";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { unregister } from "./serviceWorker";

import App from "./containers/App";
import "./styles.css";
require("typeface-poppins");

unregister();

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#1373E6",
      light: "#fff",
      dark: "#0d4a91"
    },
    secondary: {
      main: "#10B6FD",
      light: "#0066ff",
      contrastText: "#0d4a91"
    }
  },
  typography: {
    fontFamily: [
      "Poppins",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(",")
  },
  props: {
    MuiButtonBase: {
      disableRipple: true
    }
  },
  overrides: {
    MuiButton: {
      root: {
        padding: "6px 32px",
        borderRadius: "20px",
        textTransform: "none"
      }
    },
    MuiAppBar: {
      root: {
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "0 16px 0 0",
        height: 80
      },
      colorDefault: {
        backgroundColor: "#ffffff"
      }
    },
    MuiDialogTitle: {
      root: {
        padding: "16px 40px",
        borderBottom: "1px solid #eee"
      }
    },
    MuiTab: {
      root: {
        flexShrink: 1
      }
    },
    MuiFormControl: {
      marginNormal: {
        marginTop: 8,
        marginBottom: 8
      }
    },
    MuiTypography: {
      h1: {
        fontSize: "1.15rem",
        fontWeight: "600",
        color: "#888"
      }
    },
    MuiOutlinedInput: {
      input: {
        padding: "12px 14px"
      }
    },
    MuiInputLabel: {
      outlined: {
        transform: "translate(14px, 12px) scale(1)"
      }
    },
    MuiAutocomplete: {
      option: {
        '&[data-focus="true"]': {
          backgroundColor: "#1373E6",
          color: "#fff"
        }
      }
    }
  }
});

render(
  <ThemeProvider theme={theme}>
    <App />
  </ThemeProvider>,
  document.getElementById("root")
);
