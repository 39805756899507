import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
// Custom imports
import Loading from "../../components/Loading";
import PrivateRoute from "../../components/PrivateRoute";
import ChangeTempPasswordForm from "../../components/ChangePasswordForm";
import Layout from "../../containers/Layout";
import AccountSettings from "../../containers/AccountSettings";
import { createResponseInterceptor } from "../../services/Authentication";

const Login = lazy(() => import("../../components/Login"));

export default function App() {
  createResponseInterceptor();

  return (
    <Suspense fallback={<Loading isLoading={true} />}>
      <Router>
        <Switch>
          <PrivateRoute
            exact
            path="/checkviewer"
            component={() => <Layout />}
            temporary={() => <ChangeTempPasswordForm />}
          />
          <PrivateRoute
            exact
            path="/account-settings"
            component={() => <AccountSettings />}
            temporary={() => <ChangeTempPasswordForm />}
          />
          <Route path="/" component={Login} />
        </Switch>
      </Router>
    </Suspense>
  );
}
