import React, { useState, useEffect } from "react";
import { Route } from "react-router-dom";
// Custom imports
import { CreditorsContext } from "../../context/Creditors";
import {
  userIsAuthenticated,
  logOut,
  passwordIsTemporary
} from "../../services/Authentication";
import fetchApi from "../../services/FetchApi";
import getApiUrl from "../../helpers/GetApiUrl";
import getPhonepayApi from "../../helpers/GetPhonePayApi";
import Loading from "../Loading";

export default function PrivateRoute(props) {
  const { component: Component, temporary: Temporary, ...rest } = props;
  const [creditors, setCreditors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const fetchCreditors = () => {
    const url = `${getPhonepayApi()}.${getApiUrl()}/phonepay/checkviewer/creditors`;

    fetchApi("GET", url)
      .then(response => {
        if (response.status === 200) {
          setCreditors(
            response.data.sort((a, b) =>
              a.creditorName.localeCompare(b.creditorName)
            )
          );
          setLoading(false);
          setError(false);
        } else {
          throw new Error(`Error Code ${response.status}`);
        }
      })
      .catch(error => {
        // Fail silently if the creditors list isn't fetched properly. This allows the app to continue to work without the added feature.
        console.log("Error fetching creditors list", error);
        setCreditors([]);
        setLoading(false);
        setError(false);
      });
  };

  useEffect(() => {
    fetchCreditors();
  }, []);

  if (loading) {
    return <Loading isLoading={loading} error={error} />;
  } else if (passwordIsTemporary()) {
    return (
      <CreditorsContext.Provider value={creditors}>
        <Route
          {...rest}
          render={props =>
            userIsAuthenticated() === true ? <Temporary {...props} /> : logOut()
          }
        />
      </CreditorsContext.Provider>
    );
  } else {
    return (
      <CreditorsContext.Provider value={creditors}>
        <Route
          {...rest}
          render={props =>
            userIsAuthenticated() === true ? <Component {...props} /> : logOut()
          }
        />
      </CreditorsContext.Provider>
    );
  }
}
