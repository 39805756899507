export function hasSpecialCharacters(string) {
  return /[~`!#$%^&*+=\-[\]\\';,/{}|\\":<>@?]/g.test(string);
}

export function hasUpperCaseLetter(string) {
  return /[A-Z]/g.test(string);
}

export function hasMinCharacters(string, min) {
  return string.length >= min;
}

export function hasMaxCharacters(string, max) {
  return max >= string.length;
}

export function characterCountEqualsTo(string, number) {
  return number === string.length;
}

export function hasDigitsOnly(string) {
  return /^[0-9]+$/.test(string);
}

export function matchEmailFormat(string) {
  return /^([A-Za-z0-9_]+([-+.'][A-Za-z0-9_]+)*)@([A-Za-z0-9_]([-.][A-Za-z0-9_])*)+[.][A-Za-z0-9]{2,16}/.test(
    string
  );
}

export function leadingOrTrailingSpaces(string) {
  return string !== string.trim();
}

export function hasSingleQuote(string) {
  return /[`']/.test(string);
}
