import React, { useState } from "react";
import { NavLink } from "react-router-dom";
// Material-UI imports
import { AppBar, Typography, Menu, MenuItem, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
// Custom imports
import { logOut } from "../../services/Authentication";
import { ReactComponent as BrandMark } from "../../svg/brandMark.svg";
import { ReactComponent as UserIcon } from "../../svg/iconUser.svg";

export default function Navigation(props) {
  const classes = useStyles();
  const [anchorElement, setAnchorElement] = useState(null);

  function handleClick(event) {
    setAnchorElement(event.currentTarget);
  }

  function handleClose() {
    setAnchorElement(null);
  }

  return (
    <AppBar position="static" color="default" elevation={1}>
      <div className={classes.headerLeft}>
        <div className={classes.logoContainer}>
          <NavLink to="/checkviewer">
            <BrandMark alt="Global" width="80" />
          </NavLink>
        </div>
        <div>
          <Typography component="h1" variant="h1">
            <NavLink to="/checkviewer" className={classes.appName}>
              Check Verification
            </NavLink>
          </Typography>
        </div>
      </div>
      <div className={classes.headerRight}>
        <Button
          aria-controls="user-menu"
          aria-haspopup="true"
          aria-label="open profile menu"
          className={classes.menuButton}
          color="primary"
          onClick={handleClick}
        >
          <UserIcon width="32" className={classes.userIcon} />
          <Typography component="p" variant="body1">
            {props.userName}
          </Typography>
          <div className={classes.arrowDownIcon}>
            <span className={classes.leftSide}></span>
            <span className={classes.rightSide}></span>
          </div>
        </Button>
        <Menu
          id="user-menu"
          elevation={1}
          anchorEl={anchorElement}
          keepMounted
          open={Boolean(anchorElement)}
          onClose={handleClose}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          MenuListProps={{
            disablePadding: true
          }}
        >
          {sessionStorage.getItem("adUser") !== "true" && (
            <MenuItem>
              <NavLink
                className={classes.menuLink}
                to="/account-settings"
                onClick={handleClose}
              >
                Account Settings
              </NavLink>
            </MenuItem>
          )}
          <Button
            onClick={logOut}
            variant="outlined"
            color="primary"
            className={classes.logout}
          >
            Log out
          </Button>
        </Menu>
      </div>
    </AppBar>
  );
}

const useStyles = makeStyles(theme => ({
  appName: {
    margin: theme.spacing(0, 4, 0, 2),
    height: 40,
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    color: "#333",
    fontSize: "1rem",
    fontWeight: 400,
    "@media (min-width:720px)": {
      fontSize: "1.6rem"
    }
  },
  headerLeft: {
    display: "flex",
    alignItems: "center",
    "& a": {
      width: "100%",
      height: "100%"
    }
  },
  headerRight: {
    display: "flex",
    alignItems: "center"
  },
  logoContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "80px",
    height: "80px",
    backgroundColor: theme.palette.primary.main,
    "& svg": {
      width: theme.spacing(4),
      padding: theme.spacing(2, 3)
    }
  },
  menuButton: {
    height: 64,
    padding: theme.spacing(0, 2)
  },
  menuLink: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    display: "flex",
    width: "100%",
    alignItems: "center",
    padding: theme.spacing(0, 2)
  },
  logout: {
    margin: theme.spacing(2, 4)
  },
  userIcon: {
    marginRight: "8px"
  },
  arrowDownIcon: {
    position: "relative",
    width: "20px",
    height: "20px",
    marginLeft: "8px"
  },
  leftSide: {
    position: "absolute",
    width: "10px",
    height: "2px",
    background: theme.palette.primary.main,
    top: "12px",
    left: "0",
    transform: "rotate(50deg)",
    transformOrigin: "right bottom"
  },
  rightSide: {
    position: "absolute",
    width: "10px",
    height: "2px",
    background: theme.palette.primary.main,
    top: "12px",
    left: "10px",
    transform: "rotate(-50deg)",
    transformOrigin: "left bottom"
  }
}));
