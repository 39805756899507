import React, { useState } from "react";
// Material-UI imports
import {
  Typography,
  Box,
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
  Paper
} from "@material-ui/core/";
import { makeStyles } from "@material-ui/core/styles";
// Custom imports
import {
  hasSpecialCharacters,
  hasUpperCaseLetter,
  hasMinCharacters,
  characterCountEqualsTo
} from "../../services/Validation";
import fetchApi from "../../services/FetchApi";
import getApiUrl from "../../helpers/GetApiUrl";
import getPhonepayApi from "../../helpers/GetPhonePayApi";
import { logOut } from "../../services/Authentication";

export default function ChangePasswordForm() {
  const classes = useStyles();
  const [values, setValues] = useState({
    currentPassword: "",
    newPassword: "",
    verifyNewPassword: "",
    checked: false
  });
  const [hasErrors, setHasErrors] = useState({
    currentPassword: false,
    newPassword: false,
    verifyNewPassword: false
  });
  const [helperText, setHelperText] = useState({
    currentPassword: "",
    newPassword: "",
    verifyNewPassword: ""
  });

  const [uppercase, setUpperCase] = useState("");
  const [specialCharacter, setSpecialCharacter] = useState("");
  const [minCharacters, setMinCharacters] = useState("");

  const [showPassword, setShowPassword] = useState("password");
  const [confirmation, setConfirmation] = useState({
    text: "",
    classname: "",
    ariaStatus: "status"
  });

  const userName = sessionStorage.getItem("userName");

  const onSubmit = event => {
    event.preventDefault();
    const api = `${getPhonepayApi()}.${getApiUrl()}/auth/changepassword`;
    const data = {
      username: userName,
      oldPassword: values.currentPassword,
      newPassword: values.newPassword,
      appName: process.env.REACT_APP_APPNAME,
      appSecret: process.env.REACT_APP_APPSECRET
    };
    fetchApi("POST", api, data)
      .then(response => {
        if (response.status === 200) {
          if (response.data.HasErrors === true) {
            setConfirmation({
              text: `${response.data.Errors[0].message}.`,
              classname: "error",
              ariaStatus: "alert"
            });
          } else {
            setConfirmation({
              text: "Your password was updated successfully.",
              classname: "success",
              ariaStatus: "status"
            });
            setTimeout(function () {
              logOut();
            }, 3000);
          }
        } else {
          setConfirmation({
            text: `Something went wrong. Please contact us for support.`,
            classname: "error",
            ariaStatus: "alert"
          });
        }
      })
      .catch(error => {
        setHasErrors({
          currentPassword: true,
          newPassword: true,
          verifyNewPassword: true
        });
        setValues({
          currentPassword: "",
          newPassword: "",
          verifyNewPassword: "",
          checked: false
        });
        if (error.response) {
          setConfirmation({
            text: error.response.data.errors[0].message,
            classname: "error",
            ariaStatus: "alert"
          });
        } else {
          setConfirmation({
            text: `Something went wrong. Please contact us for support.`,
            classname: "error",
            ariaStatus: "alert"
          });
        }
      });
  };

  const handleCheckedChange = name => event => {
    const checked = event.target.checked;
    setValues({ ...values, [name]: checked });
    checked === true ? setShowPassword("text") : setShowPassword("password");
  };

  const handleInputChange = prop => event => {
    const value = event.target.value;
    setValues({ ...values, [prop]: value });

    // If the condition is met, highlight the password requirement in green
    if (prop === "newPassword") {
      if (hasMinCharacters(value, 8)) {
        setMinCharacters("successfulValidation");
      }
      if (hasSpecialCharacters(value)) {
        setSpecialCharacter("successfulValidation");
      }
      if (hasUpperCaseLetter(value)) {
        setUpperCase("successfulValidation");
      }
    }

    // Validation of verifyNewPassword field
    if (prop === "verifyNewPassword") {
      if (value !== values.newPassword) {
        setHelperText({
          ...helperText,
          [prop]: "New passwords must match."
        });
        setHasErrors({ ...hasErrors, [prop]: true });
      } else if (characterCountEqualsTo(value, 0)) {
        setHelperText({
          ...helperText,
          [prop]: "Password is required."
        });
        setHasErrors({ ...hasErrors, [prop]: true });
      } else {
        setHelperText({
          ...helperText,
          [prop]: ""
        });
        setHasErrors({ ...hasErrors, [prop]: false });
      }
    }
  };

  // Validation
  const onBlur = prop => event => {
    const value = event.target.value;
    if (prop === "newPassword") {
      if (characterCountEqualsTo(value, 0)) {
        if (values.newPassword !== values.verifyNewPassword) {
          setHelperText({
            ...helperText,
            newPassword: "New password is required.",
            verifyNewPassword: "New passwords must match."
          });
          setHasErrors({ ...hasErrors, [prop]: true, verifyNewPassword: true });
        } else {
          setHelperText({
            ...helperText,
            newPassword: "New password is required.",
            verifyNewPassword: ""
          });
          setHasErrors({
            ...hasErrors,
            [prop]: true,
            verifyNewPassword: false
          });
        }
      } else if (
        !hasMinCharacters(value, 8) ||
        !hasSpecialCharacters(value) ||
        !hasUpperCaseLetter(value)
      ) {
        if (values.newPassword !== values.verifyNewPassword) {
          setHelperText({
            ...helperText,
            newPassword: "Your password does not meet the requirements.",
            verifyNewPassword: "New passwords must match."
          });
          setHasErrors({ ...hasErrors, [prop]: true, verifyNewPassword: true });
        } else {
          setHelperText({
            ...helperText,
            newPassword: "Your password does not meet the requirements.",
            verifyNewPassword: ""
          });
          setHasErrors({
            ...hasErrors,
            [prop]: true,
            verifyNewPassword: false
          });
        }
      } else {
        if (values.newPassword !== values.verifyNewPassword) {
          setHelperText({
            ...helperText,
            newPassword: "",
            verifyNewPassword: "New passwords must match."
          });
          setHasErrors({
            ...hasErrors,
            [prop]: false,
            verifyNewPassword: true
          });
        } else {
          setHelperText({
            ...helperText,
            newPassword: "",
            verifyNewPassword: ""
          });
          setHasErrors({
            ...hasErrors,
            [prop]: false,
            verifyNewPassword: false
          });
        }
      }

      // If the condition is not met, highlight the password requirement in red
      if (!hasMinCharacters(value, 8)) {
        setMinCharacters("failedValidation");
      }
      if (!hasSpecialCharacters(value)) {
        setSpecialCharacter("failedValidation");
      }
      if (!hasUpperCaseLetter(value)) {
        setUpperCase("failedValidation");
      }
    }

    if (prop === "currentPassword") {
      if (characterCountEqualsTo(value, 0)) {
        setHelperText({
          ...helperText,
          [prop]: "Current password is required."
        });
        setHasErrors({ ...hasErrors, [prop]: true });
      } else {
        setHelperText({
          ...helperText,
          [prop]: ""
        });
        setHasErrors({ ...hasErrors, [prop]: false });
      }
    }

    if (prop === "verifyNewPassword") {
      if (characterCountEqualsTo(value, 0)) {
        setHelperText({
          ...helperText,
          [prop]: "Password is required."
        });
        setHasErrors({ ...hasErrors, [prop]: true });
      }
    }
  };

  const isButtonEnabled = () => {
    if (
      values.currentPassword.length > 0 &&
      values.newPassword.length > 0 &&
      values.verifyNewPassword.length > 0
    ) {
      if (Object.values(hasErrors).every(item => item === false)) {
        return true;
      }
    } else {
      return false;
    }
  };

  return (
    <Paper elevation={2} className={classes.formContainer}>
      <Box px={2} pb={2}>
        <Typography
          className={classes.moduleSubTitle}
          variant="h6"
          component="h1"
        >
          Change Password
        </Typography>
      </Box>
      <form onSubmit={onSubmit}>
        <Box px={2}>
          <TextField
            id="currentPassword"
            name="currentPassword"
            label="Current Password"
            variant="outlined"
            margin="normal"
            value={values.currentPassword}
            helperText={helperText.currentPassword}
            className="ajaxLabel"
            onChange={handleInputChange("currentPassword")}
            onBlur={onBlur("currentPassword")}
            error={hasErrors.currentPassword}
            fullWidth={true}
            autoComplete="off"
            type={showPassword}
            InputProps={{
              inputProps: { autoComplete: "off", maxLength: 64 }
            }}
            InputLabelProps={{ shrink: true, required: false }}
            required={true}
          />
        </Box>
        <Box px={2}>
          <TextField
            id="newPassword"
            name="newPassword"
            label="New password"
            variant="outlined"
            margin="normal"
            helperText={`${helperText.newPassword} Your password should`}
            value={values.newPassword}
            className="ajaxLabel"
            onChange={handleInputChange("newPassword")}
            onBlur={onBlur("newPassword")}
            error={hasErrors.newPassword}
            fullWidth={true}
            autoComplete="off"
            type={showPassword}
            InputProps={{
              inputProps: { autoComplete: "off", maxLength: 64 }
            }}
            InputLabelProps={{ shrink: true, required: false }}
            required={true}
          />
          <ul className={classes.passwordHelper}>
            <li className={`validationHelper ${uppercase}`}>
              {" "}
              include one uppercase letter
            </li>
            <li className={`validationHelper ${specialCharacter}`}>
              {" "}
              include one special character
            </li>
            <li className={`validationHelper ${minCharacters}`}>
              be at least 8 characters in length
            </li>
          </ul>
        </Box>
        <Box px={2}>
          <TextField
            id="verifyNewPassword"
            name="verifyNewPassword"
            label="Verify New Password"
            variant="outlined"
            margin="normal"
            helperText={helperText.verifyNewPassword}
            value={values.verifyNewPassword}
            className="ajaxLabel"
            onChange={handleInputChange("verifyNewPassword")}
            onBlur={onBlur("verifyNewPassword")}
            error={hasErrors.verifyNewPassword}
            fullWidth={true}
            autoComplete="off"
            type={showPassword}
            InputProps={{
              inputProps: { autoComplete: "off", maxLength: 64 }
            }}
            InputLabelProps={{ shrink: true, required: false }}
            required={true}
          />
        </Box>
        <Box px={2}>
          <FormControlLabel
            control={
              <Checkbox
                checked={values.checked}
                color="primary"
                onChange={handleCheckedChange("checked")}
                value="checked"
              />
            }
            label="Show passwords"
          />
        </Box>
        <Box m={2} className={classes.aligncenter}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={!isButtonEnabled()}
          >
            Update password
          </Button>
        </Box>
      </form>
      <Box px={2} pb={2}>
        <Typography
          className={confirmation.classname}
          role={confirmation.ariaStatus}
        >
          {confirmation.text}
          {confirmation.phone && <div>{confirmation.phone}</div>}
        </Typography>
      </Box>
    </Paper>
  );
}

const useStyles = makeStyles(theme => ({
  formContainer: {
    display: "flex",
    flexDirection: "column",
    width: "480px",
    margin: "16px auto"
  },
  moduleSubTitle: {
    paddingTop: "16px"
  },
  passwordHelper: {
    color: "rgba(0, 0, 0, 0.54)",
    fontSize: "0.75rem",
    lineHeight: "2em",
    marginTop: 0,
    paddingLeft: "1em",
    listStyle: "none"
  }
}));
