import React, { useEffect, useState, useRef } from "react";
// Material-UI imports
import { CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

export default function Timer(props) {
  const classes = useStyles();
  const [time, setTime] = useState(new Date().toLocaleTimeString());
  let countDown = useRef(props.seconds);

  useEffect(() => {
    let timer;
    if (countDown.current > 0) {
      timer = setInterval(() => {
        const date = new Date();
        countDown.current = countDown.current - 1;
        setTime(date.toLocaleTimeString());
      }, 1000);
    } else {
      window.location.reload();
    }
    return () => {
      clearInterval(timer);
    };
  }, [time]);

  return (
    <div className={classes.loading}>
      <CircularProgress size={24} />
      <span>
        {props.message ||
          "Looks like we're having trouble connecting to the server."}
        <br />
        Retrying again in{" "}
        <span className={classes.buttonCounter}>{countDown.current}</span>{" "}
        seconds
      </span>
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  loading: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    textAlign: "center",
    width: "100%",
    height: "100%",
    zIndex: 1000
  },
  buttonCounter: {
    textAlign: "center"
  }
}));
