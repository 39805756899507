import React, { Fragment, useState, useEffect, useContext } from "react";
// Material-UI imports
import {
  Typography,
  Modal,
  TextField,
  Button,
  IconButton,
  Box,
  CircularProgress,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
// Custom imports
import { CreditorsContext } from "../../context/Creditors";
import getApiUrl from "../../helpers/GetApiUrl";
import getPhonepayApi from "../../helpers/GetPhonePayApi";
import fetchApi from "../../services/FetchApi";
import HelpIcon from "@material-ui/icons/Help";
import exampleCheck1 from "./checkExample1.png";
import exampleCheck2 from "./checkExample2.png";

export default function Form(props) {
  const classes = useStyles();
  const [values, setValues] = useState({
    creditorName: "",
    memo: "",
    clientName: "",
    micrAccountNumber: "",
  });
  const [fieldErrors, setFieldErrors] = useState({
    creditorName: "",
    memo: "",
    clientName: "",
    micrAccountNumber: "",
  });
  const [isClientNameDisabled, setIsClientNameDisabled] = useState(false);
  const [isCreditorValid, setIsCreditorValid] = useState(false);
  const [isClientValid, setIsClientValid] = useState(false);
  const [isMemoValid, setIsMemoValid] = useState(false);
  const [isMicrAccountValid, setIsMicrAccountValid] = useState(false);
  const [micrVisible, setMicrVisible] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isButtonInProgress, setIsButtonInProgress] = useState(false);
  const [error, setError] = useState(false);
  const [micrModalVisible, setMicrModalVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const creditors = useContext(CreditorsContext);

  const handleErrorOpen = () => {
    setError(true);
  };
  const handleErrorClose = () => {
    setError(false);
    window.location.reload();
  };

  const handleHelpOpen = () => setMicrModalVisible(true);
  const handleHelpClose = () => setMicrModalVisible(false);

  const handleInputChange = (prop) => (event) => {
    const value = event.target.value;
    setValues({ ...values, [prop]: value });
    if (prop === "clientName") {
      if (value.length > 1 && value.length < 65) {
        setIsClientValid(true);
      } else {
        setIsClientValid(false);
      }
    }
    if (prop === "memo") {
      if (value.length > 0 && value.length < 121) {
        setIsMemoValid(true);
      } else {
        setIsMemoValid(false);
      }
    }
    if (prop === "micrAccountNumber") {
      if (value.length > 0 && value.length < 18) {
        setIsMicrAccountValid(true);
      } else {
        setIsMicrAccountValid(false);
      }
    }
  };

  const validateClientName = (event) => {
    const value = event.target.value;
    if (value.length < 65) {
      setFieldErrors({
        ...fieldErrors,
        clientName: false,
      });
    } else {
      setFieldErrors({
        ...fieldErrors,
        clientName: true,
      });
    }
  };

  const validateCreditorName = (event) => {
    const value = event.target.value;
    if (value.length < 65) {
      setFieldErrors({
        ...fieldErrors,
        creditorName: false,
      });
    } else {
      setFieldErrors({
        ...fieldErrors,
        creditorName: true,
      });
    }
  };

  const validateMemo = (event) => {
    const value = event.target.value;
    if (value.length < 121) {
      setFieldErrors({
        ...fieldErrors,
        memo: false,
      });
    } else {
      setFieldErrors({
        ...fieldErrors,
        memo: true,
      });
    }
  };

  const validateMicr = (event) => {
    const value = event.target.value;
    if (value.length < 18) {
      setFieldErrors({
        ...fieldErrors,
        micrAccountNumber: false,
      });
    } else {
      setFieldErrors({
        ...fieldErrors,
        micrAccountNumber: true,
      });
    }
  };

  const handleAutoCompleteChange = (event, inputValue) => {
    if (inputValue !== null && inputValue !== undefined) {
      setValues({ ...values, creditorName: inputValue });
      if (inputValue.length > 1 && inputValue.length < 65) {
        setIsCreditorValid(true);
      } else {
        setIsCreditorValid(false);
      }
    } else {
      setIsCreditorValid(false);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsButtonDisabled(true);
    setIsButtonInProgress(true);
    props.inProgress("");
    let creator = props.checkData.createdBy;
    if (!props.checkData.createdBy) {
      creator = props.userName;
    }

    const checkObject = {
      creditorName: values.creditorName,
      checkMemo: values.memo,
      clientName: values.clientName,
      micrAccountNumber: values.micrAccountNumber,
      docId: props.checkData.docId,
      messageQueueId: props.checkData.messageQueueId,
      processSource: props.checkData.processSource,
      createdBy: creator,
      modifiedBy: props.userName,
      x9Account: props.checkData.x9Account,
      presentedItemId: props.checkData.presentedItemId,
    };
    // Send the ajax post request to the endpoint
    const checkviewerEndpoint = `${getPhonepayApi()}.${getApiUrl()}/phonepay/checkviewer`;
    fetchApi("POST", checkviewerEndpoint, checkObject)
      .then((response) => {
        if (response.status === 200) {
          setTimeout(() => {
            setIsButtonInProgress(false);
          }, 300);
          setIsButtonDisabled(true);
          setFieldErrors({
            creditorName: "",
            memo: "",
            clientName: "",
            micrAccountNumber: "",
          });
          setIsCreditorValid(false);
          setIsClientValid(false);
          setIsMemoValid(false);
          setTimeout(() => {
            props.onChange("");
          }, 300);
        } else {
          setErrorMessage("There was an error saving the data.");
          setValues({
            creditorName: "",
            memo: "",
            clientName: "",
            micrAccountNumber: "",
          });
          handleErrorOpen();
        }
      })
      .catch((error) => {
        setErrorMessage("Failed to connect to the server.");
        handleErrorOpen();
      });
  };

  useEffect(() => {
    if (props.checkData.processSource === "VAN") {
      setValues({
        creditorName: "",
        memo: "",
        micrAccountNumber: "",
        clientName: props.checkData.clientName
          ? props.checkData.clientName
          : "",
      });
      setIsClientNameDisabled(props.checkData.clientName ? true : false);
      setIsClientValid(true);
      setMicrVisible(props.checkData.transcribeMicr);
    } else {
      setValues({
        creditorName: "",
        memo: "",
        micrAccountNumber: "",
        clientName: "",
      });
      setIsClientNameDisabled(false);
      setIsClientValid(false);
      setMicrVisible(false);
    }
    // the following line is a hack, used to make sure the Autocomplete field is cleared completely for each render
    document.querySelector(".MuiAutocomplete-clearIndicator").click();
    setTimeout(() => {
      // we don't need two separate setTimeouts here as this code is synchronous
      document.querySelector("input:not([disabled])").focus();
    }, 50);
  }, [props.checkData]);

  useEffect(() => {
    if (
      isCreditorValid &&
      isClientValid &&
      isMemoValid &&
      (micrVisible ? isMicrAccountValid : true)
    ) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [
    isCreditorValid,
    isClientValid,
    isMemoValid,
    micrVisible,
    isMicrAccountValid,
  ]);

  return (
    <Box p={2}>
      <form id="checkDetails" onSubmit={handleSubmit}>
        <TextField
          id={`clientName`}
          name="clientName"
          type="text"
          margin="normal"
          label="Client Name"
          variant="outlined"
          value={values.clientName}
          onChange={handleInputChange("clientName")}
          onBlur={validateClientName}
          fullWidth={true}
          disabled={isClientNameDisabled}
          inputProps={{ autoComplete: "off" }}
          onInput={(e) => {
            if (e.target.value.length > 64) {
              e.target.value = e.target.value.slice(0, 64);
              setFieldErrors({
                ...fieldErrors,
                clientName: true,
              });
            } else {
              setFieldErrors({
                ...fieldErrors,
                clientName: false,
              });
            }
          }}
        />
        {fieldErrors.clientName && (
          <small class="error">The input cannot exceed 64 characters.</small>
        )}
        <Autocomplete
          freeSolo={true}
          autoSelect={true}
          id={`creditorName`}
          size="small"
          value={values.creditorName}
          onChange={handleAutoCompleteChange}
          onBlur={validateCreditorName}
          options={creditors.map((option) => {
            return option.creditorName;
          })}
          onInput={(e) => {
            if (e.target.value.length > 64) {
              e.target.value = e.target.value.slice(0, 64);
              setFieldErrors({
                ...fieldErrors,
                creditorName: true,
              });
            } else {
              setFieldErrors({
                ...fieldErrors,
                creditorName: false,
              });
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Creditor Name"
              name="creditorName"
              margin="normal"
              variant="outlined"
              autoComplete="off"
            />
          )}
        />
        {fieldErrors.creditorName && (
          <small class="error">The input cannot exceed 64 characters.</small>
        )}
        <TextField
          id={`memo`}
          name="memo"
          type="text"
          margin="normal"
          label="Memo"
          variant="outlined"
          value={values.memo}
          onChange={handleInputChange("memo")}
          onBlur={validateMemo}
          fullWidth={true}
          inputProps={{ autoComplete: "off" }}
          onInput={(e) => {
            if (e.target.value.length > 120) {
              e.target.value = e.target.value.slice(0, 120);
              setFieldErrors({
                ...fieldErrors,
                memo: true,
              });
            } else {
              setFieldErrors({
                ...fieldErrors,
                memo: false,
              });
            }
          }}
        />
        {fieldErrors.memo && (
          <small class="error">The input cannot exceed 120 characters.</small>
        )}
        {micrVisible ? (
          <div className={classes.rows}>
            <TextField
              id={`micraccount`}
              name="micraccount"
              type="text"
              inputProps={{
                inputMode: "numeric",
                pattern: "[0-9]*",
              }}
              margin="normal"
              autoComplete="off"
              label="MICR Account Number"
              variant="outlined"
              value={values.micrAccountNumber}
              fullWidth={true}
              onChange={handleInputChange("micrAccountNumber")}
              onBlur={validateMicr}
              onInput={(e) => {
                if (e.target.value.length > 17) {
                  e.target.value = e.target.value.slice(0, 17);
                  setFieldErrors({
                    ...fieldErrors,
                    micrAccountNumber: true,
                  });
                } else {
                  setFieldErrors({
                    ...fieldErrors,
                    micrAccountNumber: false,
                  });
                }
              }}
            />
            <IconButton onClick={handleHelpOpen} className={classes.button}>
              <HelpIcon className={classes.icon} />
            </IconButton>
          </div>
        ) : null}

        {fieldErrors.micrAccountNumber && (
          <small className="error">
            The MICR Account Number should not exceed 17 characters.
          </small>
        )}
        <Box py={1} className={classes.buttonWrapper}>
          {isButtonInProgress ? (
            <Fragment>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={isButtonDisabled}
                className={classes.inProgress}
              >
                Submit
              </Button>
              <CircularProgress size={24} className={classes.buttonProgress} />
            </Fragment>
          ) : (
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={isButtonDisabled}
            >
              Submit
            </Button>
          )}
          <span>
            <em>
              <span className={classes.remainingCount}>
                {props.checkData.remainingCheckCount}
              </span>{" "}
              item{props.checkData.remainingCheckCount !== 1 && "s"} left
            </em>
          </span>
        </Box>
      </form>
      <Modal
        aria-labelledby="error-modal"
        open={error}
        onClose={handleErrorClose}
      >
        <div className={classes.modalContainer}>
          <Typography variant="body1" component="p">
            {errorMessage}
          </Typography>
          <Box py={1}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              onClick={handleErrorClose}
            >
              Refresh
            </Button>
          </Box>
        </div>
      </Modal>
      <Modal
        aria-labelledby="error-modal"
        open={micrModalVisible}
        onClose={handleHelpClose}
      >
        <div className={classes.helpModalContainer}>
          <Typography variant="body1" component="p">
            Example Checks with MICR Account Number
            <img src={exampleCheck1} width="650px" alt="Example Check 1"></img>
            <img src={exampleCheck2} width="650px" alt="Example Check 2"></img>
          </Typography>
          <Box py={1}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              onClick={handleHelpClose}
            >
              Close
            </Button>
          </Box>
        </div>
      </Modal>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  modalContainer: {
    position: "absolute",
    width: 400,
    backgroundColor: "#fff",
    border: "none",
    boxShadow: "0 0 5px #888",
    padding: 32,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
  },
  helpModalContainer: {
    position: "absolute",
    width: 700,
    backgroundColor: "#fff",
    border: "none",
    boxShadow: "0 0 5px #888",
    padding: 32,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
  },
  buttonWrapper: {
    display: "flex",
    position: "relative",
    justifyContent: "space-between",
    alignItems: "center",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
    color: "#fff",
  },
  inProgress: {
    opacity: ".25",
  },
  remainingCount: {
    color: theme.palette.primary.main,
    fontWeight: 700,
  },
  rows: {
    display: "flex",
    alignItems: "center",
  },
  button: {
    width: 42,
    height: 42,
    padding: 0,
  },
  icon: {
    width: 32,
    height: 32,
  },
}));
