import React from "react";
import { NavLink } from "react-router-dom";
// Material-UI imports
import { makeStyles } from "@material-ui/core/styles";
// Custom imports
import Navigation from "../../components/Navigation";
import ChangePasswordForm from "../../components/ChangePasswordForm";

export default function AccountSettings() {
  const classes = useStyles();

  return (
    <div className={classes.background}>
      <div className={classes.navigationContainer}>
        <Navigation userName={sessionStorage.userName.split("@")[0]} />
      </div>
      <div className={classes.settingsContainer}>
        <ChangePasswordForm />
        <NavLink to="/checkviewer" className={classes.backLink}>
          <div className={classes.backArrow}>
            <span className={classes.topLine}></span>
            <span className={classes.bottomLine}></span>
          </div>
          Back to Check Verification
        </NavLink>
      </div>
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  background: {
    background: "#f2f2f2",
    minHeight: "100vh"
  },
  navigationContainer: {
    flex: "0 0 100%",
    height: "60px"
  },
  settingsContainer: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "512px",
    margin: "0 auto"
  },
  backLink: {
    display: "flex",
    padding: "10px 16px",
    textDecoration: "none",
    color: theme.palette.primary.main
  },
  backArrow: {
    position: "relative",
    width: "20px",
    height: "20px"
  },
  topLine: {
    position: "absolute",
    width: "15px",
    height: "2px",
    background: theme.palette.primary.main,
    top: "10px",
    left: "0",
    transform: "rotate(40deg)",
    transformOrigin: "left bottom"
  },
  bottomLine: {
    position: "absolute",
    width: "15px",
    height: "2px",
    background: theme.palette.primary.main,
    top: "11px",
    left: "0",
    transform: "rotate(-40deg)",
    transformOrigin: "left top"
  }
}));
